var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"blue","dark":"","tile":"","elevation":"0","to":{
    name: 'tickets_search',
    params: {
      filters: {
        category: ['Kasko'],
      },
    },
  }}},[_c('v-card-title',{staticClass:"text-h3"},[_c('v-icon',{attrs:{"left":"","x-large":""}},[_vm._v("mdi-face-agent")]),_vm._v(" "+_vm._s(_vm.dashboardData(_vm.resource, 'total', 'kasko'))+" ")],1),_c('v-card-subtitle',{staticClass:"subtitle-1"},[_vm._v("Kasko")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }