<template>
  <v-card
    color="blue"
    dark
    tile
    elevation="0"
    :to="{
      name: 'tickets_search',
      params: {
        filters: {
          category: ['Kasko'],
        },
      },
    }"
  >
    <v-card-title class="text-h3">
      <v-icon left x-large>mdi-face-agent</v-icon>
      {{ dashboardData(resource, 'total', 'kasko') }}
    </v-card-title>
    <v-card-subtitle class="subtitle-1">Kasko</v-card-subtitle>
  </v-card>
</template>

<script>
import dashboard from '@mixins/dashboard.js'

export default {
  name: 'TicketsKaskoCounter',
  mixins: [dashboard],
  data: () => ({
    resource: 'tickets',
  }),
  mounted() {
    this.getDashboardData({
      resource: this.resource,
      scope: 'total',
      suffix: 'kasko',
      filters: {
        category: 'Kasko',
      },
      count: true,
    })
  },
}
</script>
