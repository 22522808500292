<template>
  <v-card flat tile outlined :loading="!chart">
    <v-toolbar flat>
      <v-toolbar-title>Assets Inventory</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col>
          <AssetsInventoryTable :data="dashboardData(resource, scope_1)" />
        </v-col>
        <v-col v-if="false" cols="3">
          <pie-chart
            v-if="chart"
            title="Assets per Prodotto"
            :messages="{ empty: 'Non ci sono dati' }"
            :data="pieChartData(scope_2)"
            :library="library"
          ></pie-chart>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="false">
      <v-btn-toggle v-model="interval" mandatory tile group>
        <v-btn value="3" small>
          3 mesi
        </v-btn>
        <v-btn value="6" small>
          6 mesi
        </v-btn>
        <v-btn value="12" small>
          12 mesi
        </v-btn>
      </v-btn-toggle>
    </v-card-actions>
  </v-card>
</template>

<script>
import dashboard from '@mixins/dashboard.js'
import AssetsInventoryTable from '@components/dashboard/AssetsInventoryTable.vue'

export default {
  name: 'AssetsTypes',
  components: { AssetsInventoryTable },
  mixins: [dashboard],
  data: () => ({
    resource: 'report',
    scope_1: 'assets_per_product_per_customer',
    scope_2: 'assets_per_product',
    interval: 3,
    options: {
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'month',
            },
          },
        ],
      },
    },
    library: {
      layout: {
        padding: {
          left: 16,
          right: 16,
          top: 0,
          bottom: 16,
        },
      },
      legend: { display: false, position: 'bottom' },
    },
  }),
  computed: {},
  watch: {
    interval: {
      immediate: true,
      handler: 'getData',
    },
  },
  methods: {
    pieChartData(scope) {
      const data = this.dashboardData(this.resource, scope) || []
      return data.map((r) => [r.label, r.count])
    },
    getData() {
      this.getReportData({
        resource: this.resource,
        scope: this.scope_1,
        interval: this.interval,
      })

      this.getReportData({
        resource: this.resource,
        scope: this.scope_2,
        interval: this.interval,
      })
    },
  },
}
</script>
