<template>
  <v-container fluid>
    <v-row>
      <v-col
        v-if="!currentCustomerId"
        cols="12"
        sm="6"
        :md="currentCustomerId ? 4 : 3"
      >
        <CustomerCounter />
      </v-col>
      <v-col cols="12" sm="6" :md="currentCustomerId ? 4 : 3">
        <AssetsCounter />
      </v-col>
      <v-col cols="12" sm="6" :md="currentCustomerId ? 4 : 3">
        <TicketsKaskoCounter />
      </v-col>
      <v-col cols="12" sm="6" :md="currentCustomerId ? 4 : 3">
        <TicketsCounter />
      </v-col>
    </v-row>

    <v-row class="mt-12">
      <v-col>
        <TicketsByCustomer />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <TicketsByMonth />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <AssetsTypes />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerCounter from '@components/dashboard/CustomerCounter.vue'
import AssetsCounter from '@components/dashboard/AssetsCounter.vue'
import TicketsCounter from '@components/dashboard/TicketsCounter.vue'
import TicketsByMonth from '@components/dashboard/TicketsByMonth.vue'
import AssetsTypes from '@components/dashboard/AssetsTypes.vue'
import TicketsKaskoCounter from '@components/dashboard/TicketsKaskoCounter.vue'
import TicketsByCustomer from '@components/dashboard/TicketsByCustomer.vue'
import { authComputed } from '@state/helpers.js'

export default {
  page: {
    title: 'Dashboard',
    meta: [{ name: 'description', content: 'The Dashboard page.' }],
  },
  components: {
    TicketsByCustomer,
    TicketsKaskoCounter,
    AssetsTypes,
    // TicketsAvgDurations,
    TicketsByMonth,
    // CustomerStats,
    // CustomersByMonth,
    TicketsCounter,
    AssetsCounter,
    CustomerCounter,
  },
  computed: {
    ...authComputed,
  },
}
</script>
