<template>
  <v-card color="blue" dark tile elevation="0" :to="{ name: 'assets_search' }">
    <v-card-title class="text-h3">
      <v-icon left x-large>mdi-devices</v-icon>
      {{ dashboardData(resource, 'total') }}
    </v-card-title>
    <v-card-subtitle class="subtitle-1">Assets</v-card-subtitle>
  </v-card>
</template>

<script>
import dashboard from '@mixins/dashboard.js'

export default {
  name: 'AssetsCounter',
  mixins: [dashboard],
  data: () => ({
    resource: 'customers/assets',
  }),
  mounted() {
    this.getDashboardData({
      resource: this.resource,
      scope: 'total',
      count: true,
    })
  },
}
</script>
