import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    chart: false,
  }),
  computed: {
    ...mapGetters('dashboard', ['dashboardData']),
  },
  mounted() {
    setTimeout(() => (this.chart = true), this.getRandomTimeout(750, 1000))
  },
  methods: {
    getRandomTimeout(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min // Il max è incluso e il min è incluso
    },
    ...mapActions('dashboard', {
      getDashboardData: 'getDataFromModel',
      getReportData: 'getReportData',
    }),
  },
}
