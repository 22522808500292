<template>
  <v-card
    color="blue"
    tile
    elevation="0"
    dark
    :to="{ name: 'customers_search' }"
  >
    <v-card-title class="text-h3">
      <v-icon left x-large>mdi-account-multiple</v-icon>
      {{ dashboardData(resource, 'total') }}
    </v-card-title>
    <v-card-subtitle class="subtitle-1">Clienti</v-card-subtitle>
  </v-card>
</template>

<script>
import dashboard from '@mixins/dashboard.js'

export default {
  name: 'AssetsCounter',
  mixins: [dashboard],
  data: () => ({
    resource: 'customers',
  }),
  mounted() {
    this.getDashboardData({
      resource: this.resource,
      scope: 'total',
      count: true,
    })
  },
}
</script>
