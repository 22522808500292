<template>
  <v-simple-table fixed-header height="240px">
    <template v-slot>
      <thead>
        <tr>
          <th class="text-left">
            Cliente
          </th>
          <th class="text-left">
            Prodotto
          </th>
          <th>
            Quantità
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data || []" :key="item.product">
          <td>{{ item.customer }}</td>
          <td>{{ item.device }}</td>
          <td>{{ item.count }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'AssetsInventoryTable',
  props: {
    data: {
      type: [Array, Object, null],
      default() {
        return []
      },
    },
  },
}
</script>
