<template>
  <v-card flat tile outlined :loading="!chart">
    <v-toolbar flat>
      <v-toolbar-title>Richieste di Assistenza</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <pie-chart
            v-if="chart"
            title="Canali di Ingaggio"
            :messages="{ empty: 'Non ci sono dati' }"
            :data="pieChartData(scope_2)"
            :library="library"
            width="240px"
          ></pie-chart>
        </v-col>
        <v-col cols="12" md="8">
          <column-chart
            v-if="chart"
            :data="chartData"
            :messages="{ empty: 'Non ci sono dati' }"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text class="mt-5">
      <h3 class="text-center">Categorie Richieste</h3>
      <v-row>
        <v-col cols="12" md="4">
          <ChartTable
            label="Categoria"
            :data="dashboardData(this.resource, this.scope_3)"
          />
        </v-col>
        <v-col cols="12" md="8">
          <column-chart
            v-if="chart"
            :data="activityData"
            :messages="{ empty: 'Non ci sono dati' }"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn-toggle v-model="interval" mandatory tile group>
        <v-btn value="3" small>
          3 mesi
        </v-btn>
        <v-btn value="6" small>
          6 mesi
        </v-btn>
        <v-btn value="12" small>
          12 mesi
        </v-btn>
      </v-btn-toggle>
    </v-card-actions>
  </v-card>
</template>

<script>
import dashboard from '@mixins/dashboard.js'
import ChartTable from '@components/dashboard/ChartTable.vue'

export default {
  name: 'TicketsByMonth',
  components: { ChartTable },
  mixins: [dashboard],
  data: () => ({
    resource: 'report',
    scope_1: 'tickets_by_month',
    scope_2: 'tickets_per_channel',
    scope_3: 'tickets_per_activity',
    interval: 3,
    options: {
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'month',
            },
          },
        ],
      },
    },
    library: {
      layout: {
        padding: {
          left: 16,
          right: 16,
          top: 0,
          bottom: 16,
        },
      },
      legend: { position: 'bottom' },
    },
  }),
  computed: {
    chartData() {
      const month = this.dashboardData(this.resource, this.scope_1) || []
      return [
        {
          name: 'Nuove Richieste per mese',
          data: month.reduce((acc, it) => {
            acc[it.label] = it.count
            return acc
          }, {}),
        },
      ]
    },
    activityData() {
      const month = this.dashboardData(this.resource, this.scope_3) || []
      return [
        {
          name: 'Categoria Richieste',
          data: month.reduce((acc, it) => {
            acc[it.label] = it.count
            return acc
          }, {}),
        },
      ]
    },
  },
  watch: {
    interval: {
      immediate: true,
      handler: 'getData',
    },
  },
  methods: {
    pieChartData(scope) {
      const data = this.dashboardData(this.resource, scope) || []
      return data.map((r) => [r.label, r.count])
    },
    getData() {
      this.getReportData({
        resource: this.resource,
        scope: this.scope_1,
        interval: this.interval,
      })

      this.getReportData({
        resource: this.resource,
        scope: this.scope_2,
        interval: this.interval,
      })

      this.getReportData({
        resource: this.resource,
        scope: this.scope_3,
        interval: this.interval,
      })
    },
  },
}
</script>
